<template>
    <div class="container">
        <div class="heading">
            <h2 class="title">Comply with <br>Global Privacy Legislation</h2>
        </div>
        <div class="legi-row">
            <div class="legi-col">
                <div class="media">
                    <img src="~@/assets/images/corporate-media-01.png" alt="corporate">
                    <div class="hexagon hexa-1"></div>
                </div>
                <div class="info">
                    <h3 class="title">Avoid Fines and Lawsuits</h3>
                    <p>Privacy Bee tracks <strong>51+ ever-changing privacy laws</strong> across the US and around the world, ensuring your website is <strong>100% legally compliant</strong>.</p>
                    <div class="badge-wrap">
                        <div class="logo-block">
                            <img src="~@/assets/images/logo-gdpr.svg" alt="GDPR">
                        </div>
                        <div class="logo-block">
                            <img src="~@/assets/images/logo-ccpa.svg" alt="CCPA">
                        </div>
                        <div class="logo-block">
                            <img src="~@/assets/images/logo-cpra.svg" alt="CPRA">
                        </div>
                        <div class="logo-block">
                            <img src="~@/assets/images/logo-lgpd.svg" alt="LGPD">
                        </div>
                        <div class="logo-block">
                            <img src="~@/assets/images/logo-dpa.svg" alt="DPA">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PrivacyLegislation'
}
</script>

<style scoped> </style>