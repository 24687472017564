<template>
    <section class="hero-banner-wrapper">
        <header class="site-header">
            <div class="brand-logo">
                <img src="~@/assets/images/vcc-privacy-bee-logo.png" alt="VCC PrivacyBee" width="415" height="58">
            </div>
        </header>
        <HeroBanner />
        <div class="geometric-hexagon">
            <video autoplay muted loop playsinline>
                <source src="~@/assets/videos/geometric-hexagon.mp4" type="video/mp4">
            </video>
        </div>
    </section>
    <section class="section-block revenue-wrapper">
        <RevenueForecast />
    </section>
    <section class="section-block how-it-works">
        <HowItWorks />
    </section>
    <section class="section-block privacy-legislation">
        <PrivacyLegislation />
    </section>
    <section class="section-block cta-block">
        <CtaBlock />
    </section>
    <section class="section-block deploy-steps">
        <DeploySteps />
    </section>
    <section class="section-block invitation-form">
        <InvitationForm />
        <footer class="site-footer">
            <SiteFooter />
        </footer>
    </section>
</template>

<script>
import HeroBanner from './components/HeroBanner.vue'
import RevenueForecast from './components/RevenueForecast.vue'
import HowItWorks from './components/HowItWorks.vue'
import PrivacyLegislation from './components/PrivacyLegislation.vue'
import CtaBlock from './components/CtaBlock.vue'
import DeploySteps from './components/DeploySteps.vue'
import InvitationForm from './components/InvitationForm.vue'
import SiteFooter from './components/SiteFooter.vue'

export default {
    name: 'App',
    components: {
        HeroBanner,
        RevenueForecast,
        HowItWorks,
        PrivacyLegislation,
        CtaBlock,
        DeploySteps,
        InvitationForm,
        SiteFooter
    },
    mounted () {
        // console.log('ddd');
        // const field = document.querySelector("input[name=range]").value;
        // console.log(field)
    }
}
</script>

<style lang="scss">
@import "~@/assets/scss/pb-theme.scss";
</style>