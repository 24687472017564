<template>
    <div class="container">
        <div class="heading">
            <h2 class="title">Request Invitation to Join Closed Beta</h2>
        </div>
        <div class="zap-form-wrapper">
            <div id="loader" class="form-loader" style="display:none;">
                <img src="~@/assets/images/hive-loader.svg" alt="Hive Loading">
            </div>
            <div id="sucess-block" class="form-message" style="display:none;">
                <img src="~@/assets/images/form-success-icon.svg" alt="" class="icon">
                <h3 class="title">Thanks!</h3>
                <p class="lead">We've received your request.</p>
                <p>Due to a high volume of requests we may not be able to respond, but if your site fits our beta criteria, we'll be in touch within the next few days.</p>
            </div>
            <div id="error-block" class="form-message" style="display:none;">
                <img src="~@/assets/images/form-error-icon.svg" alt="" class="icon">
                <h3 class="title">Ooops,</h3>
                <p class="lead">Something went wrong. Let’s try again.</p>
            </div>
            <form id="request-invitation">
                <div class="form-row col-2">
                    <div class="col">
                        <label class="control-label" for="first_name">First Name <span class="req">*</span></label>
                        <input type="text" class="form-control" id="first_name" name="first_name" autocomplete="off">
                        <span class="error-input" id="first_name_error">Please input your first name</span>
                    </div>
                    <div class="col">
                        <label class="control-label" for="last_name">Last Name <span class="req">*</span></label>
                        <input type="text" class="form-control" id="last_name" name="last_name">
                        <span class="error-input" id="last_name_error">Please input your last name</span>
                    </div>
                </diV>
                <div class="form-row col-2">
                    <div class="col">
                        <label class="control-label" for="work_email">Email <span class="req">*</span></label>
                        <input type="email" class="form-control" id="work_email" name="work_email">
                        <span class="error-input" id="work_email_error">Please input your email</span>
                        <span class="error-input" id="work_email_invalid_error">Please input valid email</span>
                    </div>
                    <div class="col">
                        <label class="control-label" for="phone">Phone <span class="req">*</span></label>
                        <input type="phone" class="form-control" id="phone" name="phone" autocomplete="off">
                        <span class="error-input" id="phone_error">Please input your phone number</span>
                    </div>
                </div>
                <div class="form-row col-2">
                    <div class="col">
                        <label class="control-label" for="website">Website <span class="req">*</span></label>
                        <input type="text" class="form-control" id="website" name="website">
                        <span class="error-input" id="website_error">Please input your website</span>
                    </div>
                    <div class="col">
                        <label class="control-label" for="monthly_traffic">Monthly Traffic <span class="req">*</span></label>
                        <input type="text" class="form-control" id="monthly_traffic" name="monthly_traffic">
                        <span class="error-input" id="monthly_traffic_error">Please input your monthly traffic</span>
                    </div>
                </div>
                <div class="form-row">
                    <label class="control-label" for="message">What are you currently using for cookie consent (if anything)? <span class="req">*</span></label>
                    <textarea class="form-control" id="message" name="message"></textarea>
                    <span class="error-input" id="message_error">Please input your message</span>
                </div>
                <div class="form-row action">
                    <button type="submit" class="btn btn-yellow" id="submit"><strong>Submit Request</strong></button>
                </div>
            </form>
        </div>
        <img src="~@/assets/images/corporate-orwell.webp" alt="Mr. Orwell" width="365" height="495" class="orwell">
    </div>
    <div class="hive-pattern">
        <video autoplay muted loop playsinline>
            <source src="~@/assets/videos/hive-pattern.mp4" type="video/mp4">
        </video>
    </div>
    <div class="pattern-overlay"></div>
</template>

<script>
export default {
    name: 'InvitationForm',
    mounted() {
        var form = document.querySelector('#request-invitation');
        form.onsubmit = function () {
            var formData = new FormData(form);
            var validateForm = validateFormInput();
            if (validateForm) {
                showHideLoader(true);
                var xhr = new XMLHttpRequest();
                //open the request
                xhr.open('POST', 'https://hooks.zapier.com/hooks/catch/10419791/3je3u44/');


                /* If we need the json format  */
                //xhr.setRequestHeader("Content-Type", "application/json");
                //var json = JSON.stringify(Object.fromEntries(formData));
                //xhr.send(json);

                const queryParams = new URLSearchParams(window.location.search);
                for(var value of queryParams.keys()) {
                    formData.append(value, queryParams.get(value));
                }

                // send browser & os info
                const browserOsDetails = getBrowserOsDetails();
                for (var key in browserOsDetails) {
                    formData.append(key, browserOsDetails[key]);
                }

                /** If we need the form data format */
                xhr.send(formData);

                xhr.onreadystatechange = function () {
                    if (xhr.readyState == XMLHttpRequest.DONE) {

                        showHideLoader(false);
                        const data = JSON.parse(this.responseText);
                        if (data.status == 'success') {
                            showHideSuccessMessage(true);
                            showHideErrorMessage(false);
                            showHideForm(false);
                            setTimeout(() => {
                                showHideForm(false);
                                showHideSuccessMessage(true);
                                form.reset();
                            }, 5000);
                        } else {
                            showHideSuccessMessage(false);
                            showHideErrorMessage(true);
                            showHideForm(true);
                        }
                    }
                }
            }

            //Dont submit the form.
            return false;
        }
        function showHideLoader(isShow) {
            document.getElementById('loader').style.display = isShow ? "flex" : "none";
        }

        function showHideForm(isShow) {
            document.getElementById('request-invitation').style.display = isShow ? "block" : "none";
        }
        function showHideSuccessMessage(isShow) {
            document.getElementById('sucess-block').style.display = isShow ? "flex" : "none";
        }
        function showHideErrorMessage(isShow) {
            document.getElementById('error-block').style.display = isShow ? "flex" : "none";
        }
        function getBrowserOsDetails() {
            //var nVer = navigator.appVersion;
            var nAgt = navigator.userAgent;
            var browserName = navigator.appName;
            var fullVersion = '' + parseFloat(navigator.appVersion);
            var majorVersion = parseInt(navigator.appVersion, 10);
            var nameOffset, verOffset, ix;

            // In Opera, the true version is after "OPR" or after "Version"
            if ((verOffset = nAgt.indexOf("OPR")) != -1) {
                browserName = "Opera";
                fullVersion = nAgt.substring(verOffset + 4);
                if ((verOffset = nAgt.indexOf("Version")) != -1)
                    fullVersion = nAgt.substring(verOffset + 8);
            }
            // In MS Edge, the true version is after "Edg" in userAgent
            else if ((verOffset = nAgt.indexOf("Edg")) != -1) {
                browserName = "Microsoft Edge";
                fullVersion = nAgt.substring(verOffset + 4);
            }
            // In MSIE, the true version is after "MSIE" in userAgent
            else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
                browserName = "Microsoft Internet Explorer";
                fullVersion = nAgt.substring(verOffset + 5);
            }
            // In Chrome, the true version is after "Chrome" 
            else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
                browserName = "Chrome";
                fullVersion = nAgt.substring(verOffset + 7);
            }
            // In Safari, the true version is after "Safari" or after "Version" 
            else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
                browserName = "Safari";
                fullVersion = nAgt.substring(verOffset + 7);
                if ((verOffset = nAgt.indexOf("Version")) != -1)
                    fullVersion = nAgt.substring(verOffset + 8);
            }
            // In Firefox, the true version is after "Firefox" 
            else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
                browserName = "Firefox";
                fullVersion = nAgt.substring(verOffset + 8);
            }
            // In most other browsers, "name/version" is at the end of userAgent 
            else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
                (verOffset = nAgt.lastIndexOf('/'))) {
                browserName = nAgt.substring(nameOffset, verOffset);
                fullVersion = nAgt.substring(verOffset + 1);
                if (browserName.toLowerCase() == browserName.toUpperCase()) {
                    browserName = navigator.appName;
                }
            }
            // trim the fullVersion string at semicolon/space if present
            if ((ix = fullVersion.indexOf(";")) != -1)
                fullVersion = fullVersion.substring(0, ix);
            if ((ix = fullVersion.indexOf(" ")) != -1)
                fullVersion = fullVersion.substring(0, ix);

            majorVersion = parseInt('' + fullVersion, 10);
            if (isNaN(majorVersion)) {
                fullVersion = '' + parseFloat(navigator.appVersion);
                majorVersion = parseInt(navigator.appVersion, 10);
            }

            var OSName = "Unknown OS";
            if (navigator.appVersion.indexOf("Win") != -1) OSName = "Windows";
            if (navigator.appVersion.indexOf("Mac") != -1) OSName = "MacOS";
            if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
            if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";

            return {
                browser: browserName + ' ' + fullVersion,
                os: OSName,
                ua: navigator.userAgent,
            }
        }
        function validateFormInput() {
            var first_name = document.forms["request-invitation"]["first_name"].value.trim();
            var last_name = document.forms["request-invitation"]["last_name"].value.trim();
            var work_email = document.forms["request-invitation"]["work_email"].value.trim();
            var phone = document.forms["request-invitation"]["phone"].value.trim();
            var website = document.forms["request-invitation"]["website"].value.trim();
            var monthly_traffic = document.forms["request-invitation"]["monthly_traffic"].value.trim();
            var message = document.forms["request-invitation"]["message"].value.trim();
            var valid = true;
            if(!first_name) {
                document.getElementById('first_name_error').style.display = "block";
                valid = false;
            } else {
                document.getElementById('first_name_error').style.display = "none";
            }
            if(!last_name) {
                document.getElementById('last_name_error').style.display = "block";
                valid = false;
            } else {
                document.getElementById('last_name_error').style.display = "none";
            }
            if(!work_email) {
                document.getElementById('work_email_error').style.display = "block";
                valid = false;
            } else {
                document.getElementById('work_email_error').style.display = "none";
                if(!validateEmail(work_email)) {
                    valid = false;
                    document.getElementById('work_email_invalid_error').style.display = "block";
                } else {
                    document.getElementById('work_email_invalid_error').style.display = "none";
                }
            }
            if(!phone) {
                document.getElementById('phone_error').style.display = "block";
                valid = false;
            } else {
                document.getElementById('phone_error').style.display = "none";
            }
            if(!website) {
                document.getElementById('website_error').style.display = "block";
                valid = false;
            } else {
                document.getElementById('website_error').style.display = "none";
            }
            if(!monthly_traffic) {
                document.getElementById('monthly_traffic_error').style.display = "block";
                valid = false;
            } else {
                document.getElementById('monthly_traffic_error').style.display = "none";
            }
            if(!message) {
                document.getElementById('message_error').style.display = "block";
                valid = false;
            } else {
                document.getElementById('message_error').style.display = "none";
            }
            return valid;

        }
        function validateEmail (email) {
            return email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        }

        ////////
        const input_range = document.querySelector("input[name=range]")
        const traffic_value = parseInt(input_range.value).toLocaleString("en-US");
        const monthly_traffic = document.querySelector("input[name=monthly_traffic]")

        monthly_traffic.setAttribute("value", traffic_value);

        input_range.addEventListener('input', function() {
            monthly_traffic.setAttribute("value", parseInt(this.value).toLocaleString("en-US"))
        })
    }
}
</script>

<style scoped> </style>