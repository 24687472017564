<template>
    <div class="container">
        <div class="heading">
            <h2 class="title">Deploy in Minutes!</h2>
        </div>
        <div class="stepper-horizontal">
            <div class="step">
                <div class="step-title">
                    <div class="icon">
                        <img src="~@/assets/images/cookie.svg" alt="">
                    </div>
                    <p>Disable any other cookie consent banner you may be using</p>
                </div>
            </div>
            <div class="step">
                <div class="step-title">
                    <div class="icon">
                        <img src="~@/assets/images/organize.svg" alt="">
                    </div>
                    <p>Organize all your existing 3rd party tags within our platform</p>
                </div>
            </div>
            <div class="step">
                <div class="step-title">
                    <div class="icon">
                        <img src="~@/assets/images/protect.svg" alt="">
                    </div>
                    <p>Paste the tag we generate for you into your website header</p>
                </div>
            </div>
            <div class="step">
                <div class="step-title">
                    <div class="icon">
                        <img src="~@/assets/images/earning.svg" alt="">
                    </div>
                    <p>Done! You're compliant and earning money!</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DeploySteps'
}
</script>

<style scoped> </style>