<template>
    <div class="container">
        <div class="border-anim-box">
            <div class="box-inner">
                <h3 class="title">Stay compliant and start earning recurring revenue in as little as 15 minutes by deploying Vendor and Cookies Consent today!</h3>
                <a href="#request-invitation" class="btn btn-yellow">Closed Beta. <strong>Request invite?</strong></a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CtaBlock'
}
</script>

<style scoped> </style>