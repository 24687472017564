<template>
    <div class="container">
        <div class="heading">
            <h2 class="title">How It Works</h2>
        </div>
        <div class="work-steps">
            <div class="step-row">
                <div class="icon">
                    <img src="~@/assets/images/icon-consent.svg" alt="">
                </div>
                <div class="panel">
                    <h4 class="title">Consent Popup</h4>
                    <p>New users visit your website, and our cookie consent banner pops up, asking for their preferences and consent - keeping your site in compliance with global privacy laws.</p>
                </div>
            </div>
            <div class="step-row">
                <div class="icon">
                    <img src="~@/assets/images/icon-save.svg" alt="">
                </div>
                <div class="panel">
                    <h4 class="title">Save Preferences?</h4>
                    <p>After selecting which vendors they trust, it asks the visitor if they want to “save their preferences” by creating a 100% free Privacy Bee account to centrally manage their consent.</p>
                </div>
            </div>
            <div class="step-row">
                <div class="icon">
                    <img src="~@/assets/images/icon-deploy.svg" alt="">
                </div>
                <div class="panel">
                    <h4 class="title">Better User Experience</h4>
                    <p>The next time the user visits your site, or any other site that uses V&CC, it will remember their preferences and not popup the banner, improving user experience.</p>
                </div>
            </div>
            <div class="step-row last">
                <div class="icon">
                    <img src="~@/assets/images/icon-scan.svg" alt="">
                </div>
                <div class="panel">
                    <h4 class="title">Protect Digital Footprint</h4>
                    <p>Perform a free scan and notify the user of any privacy exposures we find.</p>
                </div>
            </div>
            <div class="step-cta">
                <a href="#request-invitation" class="btn btn-yellow">Closed Beta. <strong data-v-2ae1ecb7="">Request invite?</strong></a>
            </div>
        </div>
    </div>
    <div class="hexa-anim-wrap">
        <div class="hexagon" id="hexagon1"></div>
        <div class="hexagon" id="hexagon2"></div>
        <div class="hexagon" id="hexagon3"></div>
        <div class="hexagon" id="hexagon4"></div>
        <div class="hexagon" id="hexagon5"></div>
    </div>
</template>

<script>
export default {
    name: 'HowItWorks'
}
</script>

<style scoped> </style>